import { arrayMove } from "@dnd-kit/sortable";
import {
  faCircleChevronDown,
  faCircleChevronUp,
  faCodeCompare,
  faEdit,
  faGripVertical,
  faPlus,
  faRankingStar,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Breadcrumb,
  Button,
  Collapse,
  ConfigProvider,
  Divider,
  Input,
  InputNumber,
  Skeleton,
  Table,
  Tabs,
} from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../../Contexts";
import Modal from "../../components/Modal";
import DraggableTable from "../../components/analytics/initiatives/DraggableTable";
import constants from "../../constants";
import agentAssistData from "../../data/Agent Assist.json";
import initiativesData from "../../data/initiatives_data.json";
import power_rankings from "../../data/power_rankings.csv";
import power_rankings_demo from "../../data/power_rankings_demo.csv";
import ranking_df from "../../data/ranking_df.csv";
import ranking_df_demo from "../../data/ranking_df_demo.csv";

export default function Initiatives() {
  const { streamId } = useParams();
  const { guildId, setActiveModal, activeModal, openNotification } =
    useContext(SiteContext);

  const specialStream = ["1", "2"].includes(streamId);

  const powerRankingsFile =
    guildId === "1346577289890234438" ? power_rankings : power_rankings_demo;
  const rankingDfFile = specialStream
    ? null
    : guildId === "1346577289890234438"
    ? ranking_df
    : ranking_df_demo;
  const [tableWidth, setTableWidth] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [powerRankings, setPowerRankings] = useState(null);
  const [rankingData, setRankingData] = useState(null);
  const [processedRankingData, setProcessedRankingData] = useState(null);
  const [cutOff, setCutOff] = useState(true);
  const [cutOffCount, setCutOffCount] = useState(5);
  const [cutOffCountInput, setCutOffCountInput] = useState(5);
  const [arrInput, setArrInput] = useState(
    JSON.parse(localStorage.getItem(`initiative_arrs_stream_${streamId}`)) || {}
  );
  const [arrActual, setArrActual] = useState(
    JSON.parse(localStorage.getItem(`initiative_arrs_stream_${streamId}`)) || {}
  );
  const [weightsInput, setWeightsInput] = useState(
    {
      churn: 3.0,
      addressedNumber: 1.0,
      feasibility: 0,
      arr: 0.0025,
      ...JSON.parse(
        localStorage.getItem(`initiative_weights_stream_${streamId}`)
      ),
    } || {
      churn: 3.0,
      addressedNumber: 1.0,
      feasibility: 0,
      arr: 0.0025,
    }
  );
  const [weights, setWeights] = useState(
    {
      churn: 3.0,
      addressedNumber: 1.0,
      feasibility: 0,
      arr: 0.0025,
      ...JSON.parse(
        localStorage.getItem(`initiative_weights_stream_${streamId}`)
      ),
    } || {
      churn: 3.0,
      addressedNumber: 1.0,
      feasibility: 0,
      arr: 0.0025,
    }
  );
  const [ranking, setRanking] = useState([]);
  const [editing, setEditing] = useState(false);
  const [proposedProcessedRankingData, setProposedProcessedRankingData] =
    useState(null);
  const [addItemInput, setAddItemInput] = useState("");

  const navigate = useNavigate();

  const csvToArr = async (file, splitter) =>
    await fetch(file)
      .then((r) => r.text())
      .then((text) => {
        const [keys, ...rest] = text
          .trim()
          .split("\n")
          .map((item) => item.split(splitter));

        const formedArr = rest.map((item) => {
          const object = {};
          keys.forEach((key, index) => (object[key] = item.at(index)));
          return object;
        });
        return formedArr;
      });

  useEffect(() => {
    csvToArr(powerRankingsFile, ",").then((result) => setPowerRankings(result));
  }, [powerRankingsFile]);

  useEffect(() => {
    if (rankingDfFile)
      csvToArr(rankingDfFile, ",").then((result) => setRankingData(result));
  }, [rankingDfFile]);

  const highestTotal =
    processedRankingData &&
    Math.max(
      ...processedRankingData.map(
        ({ "# of Feedback Items Addressed": addressedNumber }) =>
          addressedNumber
      )
    );

  const handleRect = useCallback((node) => {
    if (node) setTableWidth(node.offsetWidth);
  }, []);

  useEffect(() => {
    setRanking([]);
    if (!specialStream && rankingData)
      setProcessedRankingData(
        rankingData
          .map((item) => ({
            ...item,
            key: item.Initiative,
            title: item.Initiative,
            arr: arrActual[item.Initiative],
            "Feasibility (dev hours)": item["Feasibility"],
            score:
              item["# of Feedback Items Addressed"] * weights.addressedNumber +
              item["Churn Risk Count"] * weights.churn +
              (!isNaN(item["Feasibility"])
                ? item["Feasibility"] * weights.feasibility
                : 0) +
              (arrActual[item.Initiative]
                ? arrActual[item.Initiative] * weights.arr
                : 0),
          }))
          .sort((item1, item2) => item2.score - item1.score)
          .map((item, index) => {
            setRanking((ranking) => [...ranking, item.Initiative]);
            return { ...item, rank: index + 1 };
          })
      );
    else if (specialStream && initiativesData)
      setProcessedRankingData(
        (streamId === "2" ? agentAssistData : initiativesData)
          .map((item) => ({
            ...item,
            key: item.title,
            arr: arrActual[item.title],
            score:
              item["# of Feedback Items Addressed"] * weights.addressedNumber +
              item["Churn Risk Count"] * weights.churn +
              (!isNaN(item["Feasibility (dev hours)"])
                ? item["Feasibility (dev hours)"] * weights.feasibility
                : 0) +
              (arrActual[item.title] ? arrActual[item.title] * weights.arr : 0),
          }))
          .sort((item1, item2) => item2.score - item1.score)
          .map((item, index) => {
            setRanking((ranking) => [...ranking, item.title]);
            return { ...item, rank: index + 1 };
          })
      );
  }, [specialStream, rankingData, weights, arrActual, streamId]);

  if (!processedRankingData || !powerRankings)
    return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <div className="flex flex-row items-center gap-2">
          <Breadcrumb
            className="flex flex-row items-center -my-2"
            items={[
              { title: <a href={`/${guildId}/analytics/streams`}>Streams</a> },
              {
                title: (
                  <h1 className="softer-text">
                    {
                      { 0: "Analytics", 1: "Analytics 1", 2: "Agent Assist" }[
                        streamId
                      ]
                    }
                  </h1>
                ),
              },
            ]}
          />
        </div>
      </div>
      {streamId === "2" ? (
        <>
          <div className="flex flex-col gap-2 p-2">
            <h3>Description</h3>
            <p className="text-base">
              1. Overview
              <br />
              <br />
              Feature Description: Agent Assist is a real-time suggestion engine
              designed to provide human agents with AI-generated response drafts
              after a self-service conversation escalates to live support. This
              feature helps agents resolve customer inquiries faster while
              maintaining Ada&apos;s brand voice consistency. Agent Assist will
              display suggested responses in real time, and agents can accept,
              reject, or modify these suggestions and add coaching notes to
              improve the system over time. The human-in-the-loop training
              process will analyze differences between the initial AI suggestion
              and the agent&apos;s final response, allowing continuous
              refinement of future recommendations.
            </p>
            <Collapse
              ghost
              items={[
                {
                  key: "1",
                  label: <p className="text-base">Continue Description</p>,
                  children: (
                    <>
                      <Divider className="my-0" />
                      <p className="text-base">
                        <br />
                        2. Why It&apos;s Important
                        <br />
                        <br />• Speed & Efficiency: By providing a “first-draft”
                        response for agents to work from, Agent Assist helps
                        reduce response times and improves first-touch
                        resolution.
                        <br />• Consistency & Quality: Pre-approved content
                        ensures that agents maintain Ada&apos;s brand voice and
                        policy consistency even under pressure.
                        <br />• Continuous Improvement: The human-in-the-loop
                        training process will improve AI suggestion accuracy
                        over time by learning from corrections made by agents.
                        <br />• Agent Satisfaction: Auto-complete (similar to
                        Gmail&apos;s suggestions) and rich text editing support
                        reduce agent workload and improve overall agent
                        experience.
                        <br />• Scalability: With real-time context handoffs and
                        integration with multiple communication channels, Agent
                        Assist supports enterprises with complex handoff
                        requirements.
                        <br />
                        <br />
                      </p>
                      <Divider className="my-0" />
                      <p className="text-base">
                        <br />
                        3. Who It Is For
                        <br />
                        <br />• Human Support Agents: Agents across channels
                        (live chat, voice messaging via Amazon Connect, SMS,
                        email) who require quick, accurate response suggestions
                        during escalated conversations.
                        <br />• Contact Centers & Enterprises: Companies using
                        Ada to provide customer support and looking for
                        efficiencies via automated yet coachable response
                        suggestions.
                        <br />• Bot Managers & AI Trainers: Administrators who
                        review coaching notes and training logs to continuously
                        tune the AI&apos;s performance.
                        <br />• Integration Partners: Platforms such as
                        Salesforce, Genesys, 8x8, and others who will benefit
                        from improved handoff transcripts and integration
                        consistency.
                        <br />
                        <br />
                      </p>
                      <Divider className="my-0" />
                      <p className="text-base">
                        <br />
                        4. Feature Requirements
                        <br />
                        <br />
                        4.1 Functional Requirements
                        <br />
                        <br />
                        A. Real-Time Suggestion Engine
                        <br /> • Must provide AI-generated response suggestions
                        in real time (&lt;2 seconds latency if possible).
                        <br /> • Display suggestions based on the context of the
                        escalated conversation, utilizing a conversation summary
                        generated from the transcript (addresses issues like
                        transcript summarization improvements).
                        <br /> • Trigger suggestions immediately upon a handoff
                        event or following a specific escalation criterion.
                        <br />
                        <br />
                        B. Agent Interaction & Editing
                        <br /> • UI Panel: Present a dedicated Agent Assist
                        panel within the agent&apos;s chat interface.
                        <br />
                        <span className="pl-4" />- Display response suggestions
                        with rich text formatting capability (bold, italics,
                        hyperlinks) as specified.
                        <br />
                        <span className="pl-4" />- Auto-complete feature where
                        as agents start typing, the system offers phrase
                        completions.
                        <br /> • Interaction Buttons: Agents must have clearly
                        labeled buttons to “Accept,” “Reject,” or “Edit” the
                        suggested response.
                        <br /> • Editing: If an agent edits a suggestion, the
                        modified version is saved along with the original AI
                        output for training.
                        <br /> • “Accept All” Option: When facing multiple
                        AI-suggested training points or multi-part responses,
                        agents should have an option to accept all unmodified
                        segments in one action.
                        <br />
                        <br />
                        C. Coaching & Feedback Loop
                        <br /> • Allow agents to add coaching notes on the
                        suggestion (e.g., “Voice too casual,” “Incorrect
                        technical detail”) directly in the Agent Assist UI.
                        <br /> • Automatically store and timestamp these notes
                        with the conversation record.
                        <br /> • Feedback data will be used by the machine
                        learning training pipeline to refine future responses.
                        <br /> • Option for agents to “mark as Reviewed” to
                        indicate that coaching or changes have been
                        acknowledged.
                        <br />
                        <br />
                        D. Context & Data Integration
                        <br /> • On handoff, include associated engagement
                        context such as conversation transcript, conversation
                        summary, and any meta variables (e.g., Guideline IDs,
                        conversation topics) necessary for a complete support
                        context.
                        <br /> • For channels with integration challenges (e.g.,
                        Salesforce, Amazon Connect, NICE CXOne), ensure that
                        default transcript content is provided when missing.
                        <br /> • Enable passing contextual tokens such as user
                        data to support applications without additional manual
                        input.
                        <br />
                        <br />
                        E. Logging & Analytics
                        <br /> • Log every instance of AI suggestion delivery,
                        agent action (accept, reject, edit), and coaching note
                        submission.
                        <br /> • Provide reporting tools and API endpoints to
                        export logs with conversation attributes (e.g.,
                        distinguishes live agent escalations versus email or
                        bot-only interactions).
                        <br /> • Track metrics including time-to-response,
                        frequency of edits vs. acceptance, agent satisfaction,
                        and conversion improvements.
                        <br /> • Develop dashboards for bot managers to monitor
                        performance and training progression.
                        <br />
                        <br />
                        F. Training Pipeline
                        <br /> • Create a system service to periodically analyze
                        differences between AI suggestions and final agent
                        responses to fine-tune the underlying algorithms.
                        <br /> • Ensure that integration with the human coaching
                        loop does not interfere with primary customer
                        communication.
                        <br />
                        <br />
                        4.2 Non-Functional Requirements
                        <br />
                        <br />• Performance: Low latency in suggestion
                        generation and response recommendation.
                        <br />• Scalability: Handle concurrent escalations from
                        multiple channels without degradation.
                        <br />• Security & Data Privacy: Ensure that all
                        conversation transcripts, coaching notes, and personal
                        data comply with data protection regulations.
                        <br />• Integration Compatibility: Provide seamless
                        integration with existing handoff systems, chat widgets,
                        email processing, and partner-specific integrations
                        (Salesforce, Genesys, NICE CXOne, etc.).
                        <br />• Usability: The Agent Assist panel must be
                        intuitive and require minimal training for customer
                        support agents.
                        <br />• Reliability: Implement robust error handling,
                        such as fallback responses if the AI engine fails (e.g.,
                        “failed to send message. tap to retry” issues).
                        <br />
                        <br />
                      </p>
                      <Divider className="my-0" />
                      <p className="text-base">
                        <br />
                        5. User Stories
                        <br />
                        <br />• As an Agent, I want to see a suggested response
                        immediately after a conversation is escalated so that I
                        can quickly address the customer inquiry.
                        <br />• As an Agent, I want to edit AI suggestions and
                        add coaching notes without leaving the chat interface so
                        that I can ensure the response is accurate and on-brand.
                        <br />• As a Bot Manager, I want a dashboard to review
                        how often suggestions are accepted or edited so that I
                        can identify areas for AI training improvements.
                        <br />• As an Integration Engineer, I want Agent Assist
                        outputs to include context summaries and meta variables
                        so that partner integrations (e.g., Salesforce, Amazon
                        Connect) receive reliable conversation data.
                        <br />• As a Product Manager, I want to give agents
                        auto-complete capabilities during drafting so that
                        response composition is faster and more consistent.
                        <br />
                        <br />
                      </p>
                      <Divider className="my-0" />
                      <p className="text-base">
                        <br />
                        6. UX & Design Considerations
                        <br />
                        <br />• Agent Assist Panel:
                        <br />
                        <span className="pl-4" />- Display a prominent and
                        non-intrusive panel on the live agent interface.
                        <br />
                        <span className="pl-4" />- Include the suggested
                        response with clear visual cues (e.g., light background,
                        editable text box).
                        <br />
                        <span className="pl-4" />- Buttons for “Accept,” “Edit,”
                        and “Reject” should be easily clickable and adjacent to
                        the suggestion.
                        <br />
                        <span className="pl-4" />- Provide inline tooltips or a
                        help icon explaining how the coaching notes and feedback
                        work.
                        <br />• Responsive Design: Ensure the UI adjusts
                        gracefully for various screen sizes, including desktop
                        and mobile devices.
                        <br />• Rich Text Editor: Enable formatting (bold,
                        italics, hyperlinks) in the agent&apos;s editable text
                        field, ensuring that any pre-formatted style matches the
                        client&apos;s brand guidelines.
                        <br />
                        <br />
                      </p>
                      <Divider className="my-0" />
                      <p className="text-base">
                        <br />
                        7. Technical Architecture & Integration
                        <br />
                        <br />• Backend AI Engine: <br />
                        <span className="pl-4" />- Integrate with Ada&apos;s
                        existing AI framework to generate initial suggestions
                        based on the conversation transcript and context.
                        <br />
                        <span className="pl-4" />- Develop a microservice
                        responsible for processing coaching input and sending
                        feedback data to the ML training pipeline.
                        <br />• API Endpoints:
                        <br />
                        <span className="pl-4" />- Expose endpoints for
                        retrieving real-time suggestions and posting agent
                        actions (accept, reject, edit) along with coaching
                        notes.
                        <br />
                        <span className="pl-4" />- Ensure endpoints are secure
                        and scalable.
                        <br />• Data Storage:
                        <br />
                        <span className="pl-4" />- Log conversation transcripts,
                        agent modifications, coaching notes, and metadata in an
                        audit-compliant data store.
                        <br />
                        <span className="pl-4" />- Provide export capabilities
                        via the Data Export API for integration with third-party
                        reporting tools.
                        <br />• Integration Layers:
                        <br />
                        <span className="pl-4" />- Ensure that Agent Assist is
                        compatible with partners like Salesforce, Genesys, 8x8,
                        and others by following prior integrations.
                        <br />
                        <span className="pl-4" />- Provide configurable
                        parameters so that different channels (messaging vs.
                        voice) can have separate training and behavior.
                      </p>
                    </>
                  ),
                },
              ]}
            />
          </div>
          <Divider className="my-0" />
        </>
      ) : null}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: constants.colors.primary["light-blue"],
            fontFamily: constants.fonts["sans-header"][0],
          },
        }}
      >
        <Tabs
          tabBarExtraContent={
            <div className="flex flex-row items-center gap-2 mr-2">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: constants.colors.secondary,
                    fontFamily: constants.fonts["sans-header"],
                  },
                }}
              >
                {editing ? (
                  <>
                    <Button
                      key="editing-1"
                      className="font-sans flex flex-row items-center"
                      onClick={() => {
                        setEditing(false);
                        setProposedProcessedRankingData(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      key="editing-2"
                      className="font-sans flex flex-row items-center"
                      type="primary"
                      onClick={() => {
                        setEditing(false);
                        setProcessedRankingData(proposedProcessedRankingData);
                      }}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      key="1"
                      className="font-sans flex flex-row items-center"
                      onClick={() => setActiveModal("modify_arr")}
                    >
                      Modify ARR
                    </Button>
                    <Button
                      key="2"
                      className="font-sans flex flex-row items-center"
                      onClick={() => setActiveModal("modify_weights")}
                    >
                      Modify Scoring
                    </Button>
                    <Button
                      key="3"
                      className="font-sans flex flex-row items-center"
                      onClick={() => setActiveModal("modify_limit")}
                    >
                      Modify Limit
                    </Button>
                    <Button
                      key="4"
                      className="font-sans flex flex-row items-center"
                      type="primary"
                      onClick={() => setActiveModal("add_item")}
                      icon={<FontAwesomeIcon icon={faPlus} />}
                    >
                      Add Item
                    </Button>
                    {activeTab === 0 && (
                      <Button
                        key="5"
                        className="font-sans flex flex-row items-center"
                        type="primary"
                        onClick={() => {
                          setEditing(true);
                          setProposedProcessedRankingData(processedRankingData);
                        }}
                        icon={<FontAwesomeIcon icon={faEdit} />}
                      >
                        Edit Rank
                      </Button>
                    )}
                  </>
                )}
              </ConfigProvider>
            </div>
          }
          items={[
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faRankingStar} /> Ranking
                </span>
              ),
              key: 0,
            },
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faCodeCompare} /> Compare
                </span>
              ),
              key: 1,
              disabled: editing,
            },
          ]}
          onTabClick={(value) => {
            setActiveTab(value);
          }}
          className="w-full softer-text"
        />
      </ConfigProvider>
      {activeTab === 0 ? (
        editing ? (
          <div>
            <DraggableTable
              className="no-top"
              dataSource={proposedProcessedRankingData}
              setDataSource={setProposedProcessedRankingData}
              onDragEnd={({ active, over }) => {
                if (active.id !== over?.id) {
                  setProposedProcessedRankingData((prev) => {
                    const activeIndex = prev.findIndex(
                      (i) => i.key === active.id
                    );
                    const overIndex = prev.findIndex((i) => i.key === over?.id);
                    let result = arrayMove(prev, activeIndex, overIndex);
                    return result.map((item, index) => ({
                      ...item,
                      rank: index + 1,
                    }));
                  });
                }
              }}
              columns={[
                {
                  key: "grip",
                  title: null,
                  render: () => (
                    <FontAwesomeIcon
                      className="text-shade-medium"
                      size="xl"
                      icon={faGripVertical}
                    />
                  ),
                },
                {
                  dataIndex: "rank",
                  key: "rank",
                  title: <p className="text-lg leading-4 font-bold">#</p>,
                  width: 20,
                  render: (rank) => (
                    <InputNumber
                      className="w-16 -my-2"
                      key={rank}
                      min={1}
                      max={proposedProcessedRankingData.length}
                      defaultValue={rank}
                      onPressEnter={({ target }) => {
                        const value = Math.max(
                          Math.min(
                            parseInt(target.value),
                            proposedProcessedRankingData.length
                          ),
                          1
                        );
                        if (rank !== value) {
                          setProposedProcessedRankingData((prev) => {
                            const activeIndex = prev.findIndex(
                              (i) => i.rank === rank
                            );
                            const overIndex = prev.findIndex(
                              (i) => i.rank === value
                            );
                            let result = arrayMove(
                              prev,
                              activeIndex,
                              overIndex
                            );
                            return result.map((item, index) => ({
                              ...item,
                              rank: index + 1,
                            }));
                          });
                        }
                      }}
                      onStep={(inputValue) => {
                        const value = Math.max(
                          Math.min(
                            parseInt(inputValue),
                            proposedProcessedRankingData.length
                          ),
                          1
                        );
                        if (rank !== value) {
                          setProposedProcessedRankingData((prev) => {
                            const activeIndex = prev.findIndex(
                              (i) => i.rank === rank
                            );
                            const overIndex = prev.findIndex(
                              (i) => i.rank === value
                            );
                            let result = arrayMove(
                              prev,
                              activeIndex,
                              overIndex
                            );
                            return result.map((item, index) => ({
                              ...item,
                              rank: index + 1,
                            }));
                          });
                        }
                      }}
                    />
                  ),
                },
                {
                  dataIndex: "title",
                  key: "title",
                  title: "Initiative",
                },
                {
                  dataIndex: "Churn Risk Count",
                  key: "Churn Risk Count",
                  title: "Churn",
                  width: 20,
                },
                {
                  dataIndex: "# of Feedback Items Addressed",
                  key: "# of Feedback Items Addressed",
                  title: <p className="whitespace-nowrap"># Addressed</p>,
                  width: 20,
                },
                {
                  dataIndex: "% of Total Feedback Addressed",
                  key: "% of Total Feedback Addressed",
                  title: <p className="whitespace-nowrap">% Addressed</p>,
                  width: 20,
                  render: (value) => parseFloat(value).toFixed(2),
                },
                {
                  dataIndex: "Feasibility (dev hours)",
                  key: "Feasibility (dev hours)",
                  title: (
                    <p className="whitespace-nowrap">Feasibility (dev hours)</p>
                  ),
                  width: 20,
                },
                ...(processedRankingData.filter((item) => arrActual[item.title])
                  .length
                  ? [
                      {
                        dataIndex: "arr",
                        key: "arr",
                        title: <p className="whitespace-nowrap">ARR</p>,
                        width: 20,
                      },
                    ]
                  : []),
                {
                  dataIndex: "score",
                  key: "score",
                  title: <p className="whitespace-nowrap">Score</p>,
                  width: 20,
                },
                {
                  key: "delete",
                  title: null,
                  render: (_, __, index) => (
                    <Button
                      className="-my-2"
                      danger
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() =>
                        setProposedProcessedRankingData((data) => [
                          ...data.slice(0, index),
                          ...data
                            .slice(index + 1)
                            .map((item) => ({ ...item, rank: item.rank - 1 })),
                        ])
                      }
                    />
                  ),
                },
              ]}
            />
          </div>
        ) : (
          <div>
            <Table
              className="no-top"
              ref={handleRect}
              tableLayout="auto"
              dataSource={processedRankingData.filter(
                cutOff ? ({ rank }) => rank <= cutOffCount : () => true
              )}
              columns={[
                {
                  dataIndex: "rank",
                  key: "rank",
                  title: <p className="text-lg leading-4 font-bold">#</p>,
                  width: 20,
                  render: (
                    rank,
                    {
                      "# of Feedback Items Addressed": addressedNumber,
                      "Churn Risk Count": churn,
                    }
                  ) => (
                    <div className="flex flex-row items-center gap-0 w-full h-10 -my-4">
                      <div className="w-0 z-10 flex flex-row justify-start">
                        <div className="m-2.5 text-lg font-bold">{rank}</div>
                      </div>
                      <div
                        className="absolute left-0 top-0 bottom-0 py-2 px-4 box-border"
                        style={{ width: tableWidth || 0 }}
                      >
                        <div
                          className={`bg-dark-gray-base dark:bg-dark-gray-medium h-full rounded-lg ${
                            rank > cutOffCount ? "opacity-50" : "opacity-100"
                          }`}
                          style={{
                            width: (addressedNumber / highestTotal) * 100 + "%",
                          }}
                        >
                          <div
                            className={`bg-red-dark h-full rounded-lg ${
                              rank > cutOffCount ? "opacity-75" : "opacity-100"
                            }`}
                            style={{
                              width: (churn / addressedNumber) * 100 + "%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                  sorter: (item1, item2) => item1.rank - item2.rank,
                },
                {
                  dataIndex: "title",
                  key: "title",
                  title: "Initiative",
                  render: (name) => (
                    <div className="px-1 bg-white/50 dark:bg-dark-white/50 leading-5 rounded w-fit">
                      {name}
                    </div>
                  ),
                },
                {
                  dataIndex: "Churn Risk Count",
                  key: "Churn Risk Count",
                  title: (
                    <div className="flex flex-row items-center gap-0">
                      <div className="flex flex-row justify-end w-0 overflow-visible">
                        <div className="shrink-0 w-3 h-3 bg-red-dark rounded-sm mx-1" />
                      </div>
                      <p>Churn</p>
                    </div>
                  ),
                  width: 20,
                  sorter: (item1, item2) =>
                    item1["Churn Risk Count"] - item2["Churn Risk Count"],
                },
                {
                  dataIndex: "# of Feedback Items Addressed",
                  key: "# of Feedback Items Addressed",
                  title: (
                    <div className="flex flex-row items-center gap-0">
                      <div className="flex flex-row justify-end w-0 overflow-visible">
                        <div className="shrink-0 w-3 h-3 bg-dark-gray-base dark:bg-dark-gray-medium rounded-sm mx-1" />
                      </div>
                      <p className="whitespace-nowrap"># Addressed</p>
                    </div>
                  ),
                  width: 20,
                  sorter: (item1, item2) =>
                    item1["# of Feedback Items Addressed"] -
                    item2["# of Feedback Items Addressed"],
                },
                {
                  dataIndex: "% of Total Feedback Addressed",
                  key: "% of Total Feedback Addressed",
                  title: <p className="whitespace-nowrap">% Addressed</p>,
                  width: 20,
                  sorter: (item1, item2) =>
                    item1["% of Total Feedback Addressed"] -
                    item2["% of Total Feedback Addressed"],
                  render: (value) => parseFloat(value).toFixed(2),
                },
                {
                  dataIndex: "Feasibility (dev hours)",
                  key: "Feasibility (dev hours)",
                  title: (
                    <p className="whitespace-nowrap">Feasibility (dev hours)</p>
                  ),
                  width: 20,
                  sorter: (item1, item2) =>
                    item1["Feasibility (dev hours)"] -
                    item2["Feasibility (dev hours)"],
                },
                ...(processedRankingData.filter((item) => arrActual[item.title])
                  .length
                  ? [
                      {
                        dataIndex: "arr",
                        key: "arr",
                        title: <p className="whitespace-nowrap">ARR</p>,
                        width: 20,
                        sorter: (item1, item2) => item1.arr - item2.arr,
                      },
                    ]
                  : []),
                {
                  dataIndex: "score",
                  key: "score",
                  title: <p className="whitespace-nowrap">Score</p>,
                  width: 20,
                  sorter: (item1, item2) => item1.score - item2.score,
                },
              ]}
              rowClassName="cursor-pointer"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate(
                      `/${guildId}/analytics/streams/${streamId}/${encodeURIComponent(
                        record.title
                      )}`
                    );
                  },
                };
              }}
            />
            <div className="flex flex-row justify-end w-full">
              <Button
                type="text"
                onClick={() => setCutOff((cutOff) => !cutOff)}
              >
                {cutOff ? "Show Extra Items" : "Hide Extra Items"}
              </Button>
            </div>
          </div>
        )
      ) : activeTab === 1 ? (
        <Table
          className="no-top"
          ref={handleRect}
          tableLayout="auto"
          dataSource={powerRankings
            .map((item, index) => ({
              ...item,
              rank: rankingDfFile
                ? ranking.findIndex(
                    (findItem) => findItem === item.Initiative
                  ) + 1
                : item["Our Rank"],
              key: index,
            }))
            .sort((item1, item2) => item1.rank - item2.rank)}
          columns={[
            {
              dataIndex: "Initiative",
              key: "Initiative",
              title: "Initiative",
              render: (title) => <p className="text-base">{title}</p>,
            },
            {
              dataIndex: "Sales Rank",
              key: "Sales Rank",
              title: "Sales Rank",
              sorter: (item1, item2) =>
                item1["Sales Rank"] - item2["Sales Rank"],
            },
            {
              dataIndex: "rank",
              key: "rank",
              title: "Our Rank",
              render: (rank) => <p className="font-bold">{rank}</p>,
              sorter: (item1, item2) => item1.rank - item2.rank,
            },
            {
              key: "Change",
              title: "Change",
              render: (_, { rank, "Sales Rank": salesRank }) => {
                const delta = rank - salesRank;
                if (delta < 0)
                  return (
                    <p className="text-green-dark">
                      <FontAwesomeIcon icon={faCircleChevronUp} /> {-delta}
                    </p>
                  );
                else if (delta > 0)
                  return (
                    <p className="text-red-dark">
                      <FontAwesomeIcon icon={faCircleChevronDown} /> {delta}
                    </p>
                  );
                else return <p>&mdash;</p>;
              },
              sorter: (item1, item2) =>
                item1.rank - item1.salesRank - (item2.rank - item2.salesRank),
            },
          ]}
          rowClassName="cursor-pointer"
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate(
                  `/${guildId}/analytics/streams/${streamId}/${encodeURIComponent(
                    record.title
                  )}`
                );
              },
            };
          }}
        />
      ) : null}
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={activeModal === "modify_arr"}
        onClose={() => {
          setActiveModal(null);
          setArrInput(
            JSON.parse(
              localStorage.getItem(`initiative_arrs_stream_${streamId}`)
            ) || arrActual
          );
        }}
        header="Modify ARRs"
        closeButton
      >
        {processedRankingData ? (
          <div className="flex flex-col gap-2 h-full overflow-hidden">
            <div className="overflow-y-auto">
              <Table
                className="grow filled-header"
                sticky={true}
                tableLayout="auto"
                dataSource={processedRankingData.map((item, key) => ({
                  ...item,
                  key,
                }))}
                columns={[
                  {
                    dataIndex: "title",
                    key: "title",
                    title: "Initiative",
                  },
                  {
                    key: "arr",
                    title: "ARR",
                    width: 150,
                    render: (_, { title: initiative }) => (
                      <InputNumber
                        value={arrInput[initiative]}
                        onChange={(value) =>
                          setArrInput((arrInput) => ({
                            ...arrInput,
                            [initiative]: value || undefined,
                          }))
                        }
                      />
                    ),
                  },
                ]}
                size="small"
                pagination={false}
              />
            </div>
            <div className="flex flex-row-reverse w-full">
              <Button
                type="primary"
                onClick={() => {
                  localStorage.setItem(
                    `initiative_arrs_stream_${streamId}`,
                    JSON.stringify(arrInput)
                  );
                  setArrActual(arrInput);
                  openNotification(
                    "success",
                    "ARRs Saved",
                    "The inputted ARR values have been saved to local storage."
                  );
                  setActiveModal(null);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={activeModal === "modify_weights"}
        onClose={() => {
          setActiveModal(null);
          setWeightsInput(
            JSON.parse(
              localStorage.getItem(`initiative_weights_stream_${streamId}`)
            ) || weights
          );
        }}
        header="Modify Scoring Formula"
        closeButton
      >
        {processedRankingData ? (
          <div className="flex flex-col gap-2 h-full overflow-hidden">
            <div className="overflow-y-auto">
              <Table
                className="grow filled-header"
                sticky={true}
                tableLayout="auto"
                dataSource={Object.entries(weightsInput).map(
                  ([value, weight], key) => ({ value, weight, key })
                )}
                columns={[
                  {
                    dataIndex: "value",
                    key: "value",
                    title: "Value",
                    render: (value) =>
                      ({
                        churn: "Churn",
                        addressedNumber: "# Addressed",
                        feasibility: "Feasibility (dev hours)",
                        arr: "ARR",
                      }[value]),
                  },
                  {
                    key: "weight",
                    title: "Weight",
                    width: 150,
                    render: (_, { value }) => (
                      <InputNumber
                        value={weightsInput[value]}
                        onChange={(inputValue) =>
                          setWeightsInput((weightsInput) => ({
                            ...weightsInput,
                            [value]: inputValue,
                          }))
                        }
                      />
                    ),
                  },
                ]}
                size="small"
                pagination={false}
              />
            </div>
            <div className="flex flex-row-reverse w-full">
              <Button
                type="primary"
                onClick={() => {
                  localStorage.setItem(
                    `initiative_weights_stream_${streamId}`,
                    JSON.stringify(weightsInput)
                  );
                  setWeights(weightsInput);
                  openNotification(
                    "success",
                    "Scoring Formula Saved",
                    "The inputted scoring values have been saved to local storage."
                  );
                  setActiveModal(null);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={activeModal === "modify_limit"}
        onClose={() => {
          setActiveModal(null);
          setCutOffCountInput(cutOffCount);
        }}
        header="Modify Item Limit"
        closeButton
      >
        <div className="flex flex-col gap-2 h-full overflow-hidden">
          <InputNumber
            className="w-full"
            value={cutOffCountInput}
            onChange={setCutOffCountInput}
          />
          <div className="flex flex-row-reverse w-full">
            <Button
              type="primary"
              onClick={() => {
                setCutOffCount(cutOffCountInput);
                openNotification(
                  "success",
                  "Item Limit Saved",
                  "The inputted item limit value has been applied."
                );
                setActiveModal(null);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={activeModal === "add_item"}
        onClose={() => {
          setActiveModal(null);
          setAddItemInput("");
        }}
        header="Add Initiative"
        closeButton
      >
        <div className="flex flex-col gap-2 w-96 overflow-hidden">
          <label className="flex flex-col gap-2">
            <p className="text-sm">Initiative Name:</p>
            <Input
              className="w-full"
              value={addItemInput}
              onChange={({ target }) => setAddItemInput(target.value)}
            />
          </label>
          <p className="text-sm font-normal softer-text">
            New initiatives must be processed to find feedback and will take
            some time to appear in the list.
          </p>
          <div className="flex flex-row-reverse w-full">
            <Button
              type="primary"
              onClick={() => {
                setAddItemInput("");
                openNotification(
                  "success",
                  "New Item Processing",
                  "The inputted item is being processed and will appear shortly."
                );
                setActiveModal(null);
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
