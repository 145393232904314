import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SiteContext } from "../../Contexts";
import Card from "../../components/Card";

export default function Streams() {
  const { guildId } = useContext(SiteContext);
  const navigate = useNavigate();

  const streams = [{ id: 0, name: "Analytics" }];
  if (guildId === "1346577289890234438")
    streams.push(
      { id: 1, name: "Analytics 1" },
      { id: 2, name: "Agent Assist" }
    );

  const streamsTable = useCallback(
    (streams) => (
      <div className="flex flex-wrap -m-3">
        {streams.map(({ id, name }) => (
          <div key={id} className="w-full lg:w-1/2 xl:w-1/3 p-1.5 box-border">
            <Card
              className={`relative overflow-hidden p-[0px] group
                    before:content-[''] before:absolute before:inset-0 before:transition-all
                    cursor-pointer hover:before:bg-black/5 dark:hover:before:bg-white/5`}
              onClick={() => navigate(`/${guildId}/analytics/streams/${id}`)}
            >
              <div className="flex flex-col justify-between items-stretch p-4 gap-2">
                <div className="flex flex-row justify-between items-start gap-1">
                  <div className="flex flex-row items-center gap-2 overflow-hidden">
                    <p className="shrink font-normal text-md whitespace-nowrap overflow-hidden text-ellipsis w-full">
                      {name}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-end gap-1">
                  <p className="text-sm text-gray-medium dark:text-dark-gray-medium">
                    {id}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    ),
    [navigate, guildId]
  );

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Streams</h1>
      </div>
      <div className="flex flex-col gap-5 px-4 sm:px-6 pt-2 pb-6">
        {streamsTable(streams)}
      </div>
    </>
  );
}
