import {
  faCheck,
  faCircleExclamation,
  faKey,
  faLock,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ConfigProvider, Form, Input, theme, Tooltip } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AccountContext } from "../../Contexts";
import Card from "../../components/Card";
import LandingFooter from "../../components/landing/LandingFooter";
import LandingHeader from "../../components/landing/LandingHeader";
import constants from "../../constants";
import AuthManager from "../../scripts/AuthManager";
import Eye from "../../components/Eye";

export default function ForgotPassword() {
  const { signedIn } = useContext(AccountContext);
  const [mode, setMode] = useState("enter");
  const [result, setResult] = useState(null);
  const [username, setUsername] = useState(null);
  const [code, setCode] = useState(null);
  const [codeStatus, setCodeStatus] = useState(null);
  const [codeWarning, setCodeWarning] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordStatus, setPasswordStatus] = useState(null);
  const [passwordReqs, setPasswordReqs] = useState({
    number: false,
    special: false,
    upper: false,
    lower: false,
    length: false,
  });
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [passwordConfirmStatus, setPasswordConfirmStatus] = useState(null);
  const [passwordConfirmWarning, setPasswordConfirmWarning] = useState(null);
  const [warning, setWarning] = useState(null);
  const navigate = useNavigate();

  const checkPassword = useCallback(() => {
    if (!password || password === "") {
      setPasswordStatus(null);
      return true;
    } else if (Object.values(passwordReqs).every((value) => value === true)) {
      setPasswordStatus(null);
      return true;
    } else {
      setPasswordStatus("error");
      return false;
    }
  }, [password, passwordReqs]);

  const checkPasswordConfirm = useCallback(() => {
    let valid = password === passwordConfirm;
    setPasswordConfirmStatus(valid ? null : "error");
    setPasswordConfirmWarning(valid ? null : "Must match");
    return valid;
  }, [password, passwordConfirm]);

  useEffect(() => {
    setPasswordReqs({
      number: /\d/.test(password),
      special: /[^\w\s]/.test(password),
      lower: /[a-z]/.test(password) && password !== null,
      upper: /[A-Z]/.test(password),
      length: /^.{8,}$/.test(password),
    });
  }, [password, setPasswordReqs]);

  useEffect(() => {
    setCodeStatus(null);
    setPasswordStatus(null);
  }, [mode]);

  useEffect(() => {
    if (result === "verify") setMode("verify");
    switch (result) {
      case "verify":
        setMode("verify");
        break;
      case "success":
        navigate("/");
        break;
      default:
        setWarning(result);
        break;
    }
  }, [result, navigate]);

  useEffect(() => setWarning(null), [mode]);

  useEffect(() => {
    if (signedIn) navigate("/home", { replace: true });
  });

  if (signedIn === null || signedIn)
    return (
      <div className="flex flex-col gap-6 items-center justify-center min-h-screen py-6 box-border overflow-auto bg-gray-medium-light dark:bg-dark-gray-medium-light"></div>
    );

  return (
    <div className="relative no-zoom">
      <div className="-z-10 absolute inset-0 bg-white" />
      <div className="-z-10 absolute w-full flex flex-row justify-center top-[50px]">
        <div className="landing-bg h-[400px] w-[400px]" />
      </div>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: constants.colors["secondary"],
          },
        }}
      >
        <div className="static min-h-screen flex flex-col">
          <LandingHeader
            simple
            landing
            className="fixed box-border h-12 sm:h-16 w-full"
          />
          <div className="h-16 sm:h-0" />
          <Form className="flex flex-col items-center justify-center gap-4 sm:min-h-screen sm:py-20">
            <div className="flex flex-row items-center gap-2">
              <Eye height={24} width={30} />
              <p className="font-landing-header text-xl text-secondary font-semibold">
                Reset Password
              </p>
            </div>
            <Card
              noMode
              className="sm:self-center sm:w-96 h-fit bg-black/10 border-black/20 border-[1px] border-solid self-stretch mx-8"
            >
              <div className="flex flex-col gap-3">
                {mode !== "verify" ? (
                  <label className="flex flex-col gap-px">
                    <p className="font-landing text-black">Username</p>
                    <Input
                      name="username"
                      title="Username or Email"
                      prefix={
                        <FontAwesomeIcon
                          icon={faUser}
                          size="sm"
                          className="mr-1"
                        />
                      }
                      size="large"
                      placeholder="Username or Email"
                      onChange={({ target }) => setUsername(target.value)}
                      value={username}
                    />
                  </label>
                ) : null}
                {mode === "verify" ? (
                  <>
                    <div>
                      <Input.Password
                        title="Password"
                        onFocus={() => {
                          setPasswordStatus(null);
                        }}
                        onBlur={() => {
                          checkPassword();
                        }}
                        status={passwordStatus}
                        prefix={
                          <FontAwesomeIcon
                            icon={faLock}
                            size="sm"
                            className="mr-1"
                          />
                        }
                        size="large"
                        placeholder="Password"
                        onChange={({ target }) => setPassword(target.value)}
                        value={password}
                      />
                      <div className="pl-3 pt-1">
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.length ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.length
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 8 letters
                        </p>
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.number ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.number
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 1 number
                        </p>
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.special ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.special
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 1 special character
                        </p>
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.lower ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.lower
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 1 lowercase letter
                        </p>
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.upper ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.upper
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 1 uppercase letter
                        </p>
                      </div>
                    </div>
                    <Tooltip title={passwordConfirmWarning} color="red">
                      <div>
                        <Input.Password
                          title="Confirm Password"
                          onFocus={() => {
                            setPasswordConfirmStatus(null);
                            setPasswordConfirmWarning(null);
                          }}
                          onBlur={() => {
                            checkPasswordConfirm();
                          }}
                          status={passwordConfirmStatus}
                          prefix={
                            <FontAwesomeIcon
                              icon={faLock}
                              size="sm"
                              className="mr-1"
                            />
                          }
                          size="large"
                          placeholder="Confirm Password"
                          onChange={({ target }) =>
                            setPasswordConfirm(target.value)
                          }
                          value={passwordConfirm}
                        />
                      </div>
                    </Tooltip>
                  </>
                ) : null}
                {mode === "verify" ? (
                  <Tooltip title={codeWarning} color="red">
                    <Input
                      prefix={
                        <FontAwesomeIcon
                          icon={faKey}
                          size="sm"
                          className="mr-1"
                        />
                      }
                      onFocus={() => {
                        setCodeStatus(null);
                        setCodeWarning(null);
                      }}
                      status={codeStatus}
                      size="large"
                      placeholder="Verification Code"
                      onChange={({ target }) => setCode(target.value)}
                      value={code}
                    />
                  </Tooltip>
                ) : null}
                {mode === "enter" ? (
                  <Button
                    type="primary"
                    className="self-center w-[80%] h-16 bg-[#FFD86D] my-4"
                    onClick={() => {
                      AuthManager.resetPassword(username, setResult);
                    }}
                    htmlType="submit"
                  >
                    <span className="font-landing-header capitalize text-2xl font-semibold text-black">
                      Send Code
                    </span>
                  </Button>
                ) : mode === "verify" ? (
                  <Button
                    type="primary"
                    className="self-center w-[80%] h-16 bg-[#FFD86D] my-4"
                    onClick={() => {
                      if (
                        code &&
                        password &&
                        checkPassword() &&
                        passwordConfirm &&
                        checkPasswordConfirm()
                      ) {
                        setWarning(null);
                        AuthManager.confirmPassword(
                          username,
                          code,
                          password,
                          setResult
                        );
                      } else {
                        setWarning("Some fields have problems");
                        if (!password) setPasswordStatus("error");
                        if (!passwordConfirm) setPasswordConfirmStatus("error");
                        if (!code) {
                          setCodeStatus("error");
                          setCodeWarning("Field cannot be empty");
                        }
                      }
                    }}
                    htmlType="submit"
                  >
                    <span className="font-landing-header capitalize text-2xl font-semibold text-black">
                      Reset
                    </span>
                  </Button>
                ) : null}
                {warning ? (
                  <div className="flex flex-row items-center gap-1">
                    <FontAwesomeIcon
                      className="text-red-dark"
                      icon={faCircleExclamation}
                      size="sm"
                    />
                    <p className="text-sm text-red-dark">{warning}</p>
                  </div>
                ) : null}
                <hr />
                <p className="font-heading text-xs text-black font-light">
                  <Link className="underline decoration-from-font" to="/login">
                    Go Back
                  </Link>
                </p>
              </div>
            </Card>
          </Form>
          <LandingFooter noRegister />
        </div>
      </ConfigProvider>
    </div>
  );
}
