import { Divider, Table } from "antd";
import { useCallback, useRef, useState } from "react";
import Modal from "../../components/Modal";

export default function CoreIssues() {
  const [expanded, setExpanded] = useState(false);
  const [expandedRecord, setExpandedRecord] = useState(null);
  const [tableWidth, setTableWidth] = useState(null);

  const expandedEndRef = useRef(null);

  const data = [
    {
      name: "Manage Multiple Languages",
      churn: 16,
      total: 73,
      description:
        "Customers want support for languages beside English so that they can support international platforms.",
      documents: [
        { name: "Big Business Report", issues: 12 },
        { name: "Medium Business Email", issues: 3 },
        { name: "Random Email", issues: 7 },
        { name: "Random Document", issues: 22 },
        { name: "Doc 123", issues: 11 },
        { name: "New Doc", issues: 6 },
        { name: "This Document", issues: 7 },
        { name: "Short Email", issues: 2 },
      ],
    },
    {
      name: "More Human Speech",
      churn: 8,
      total: 102,
      description:
        "The language that the chat bot uses is too formal and rigid and would benefit from having more casual and dynamic responses.",
      documents: [
        { name: "Big Business Report", issues: 12 },
        { name: "Medium Business Email", issues: 3 },
      ],
    },
    {
      name: "Better Documentation",
      churn: 5,
      total: 64,
      description:
        "Documentation for use and development for the service is unorganized and customers have difficulty finding the necessary information for their development.",
      documents: [
        { name: "Big Business Report", issues: 12 },
        { name: "Medium Business Email", issues: 3 },
      ],
    },
  ];
  const highestTotal = Math.max(...data.map(({ total }) => total));

  const handleRect = useCallback((node) => {
    if (node) setTableWidth(node.offsetWidth);
  }, []);

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Core Issues</h1>
      </div>
      <Table
        className="no-top"
        ref={handleRect}
        tableLayout="auto"
        dataSource={data.map((item) => ({ ...item, key: item.name }))}
        columns={[
          {
            dataIndex: "name",
            key: "name",
            title: "Issue",
            render: (name, { total, churn }) => (
              <div className="flex flex-row items-center gap-0 w-full h-10 -my-4">
                <div className="w-0 z-10 flex flex-row justify-start">
                  <div className="shrink-0 m-2.5 px-1 bg-white/50 dark:bg-dark-white/50 leading-5 rounded">
                    {name}
                  </div>
                </div>
                <div
                  className="absolute left-0 top-0 bottom-0 py-2 px-4 box-border"
                  style={{ width: tableWidth || 0 }}
                >
                  <div
                    className="bg-dark-gray-base dark:bg-dark-gray-medium h-full rounded-lg"
                    style={{ width: (total / highestTotal) * 100 + "%" }}
                  >
                    <div
                      className="bg-red-dark h-full rounded-lg"
                      style={{ width: (churn / total) * 100 + "%" }}
                    />
                  </div>
                </div>
              </div>
            ),
          },
          {
            dataIndex: "churn",
            key: "churn",
            title: (
              <div className="flex flex-row items-center gap-0">
                <div className="flex flex-row justify-end w-0 overflow-visible">
                  <div className="shrink-0 w-3 h-3 bg-red-dark rounded-sm mx-1" />
                </div>
                <p>Churn</p>
              </div>
            ),
            width: 20,
            sorter: (item1, item2) => item1.churn - item2.churn,
          },
          {
            dataIndex: "total",
            key: "total",
            title: (
              <div className="flex flex-row items-center gap-0">
                <div className="flex flex-row justify-end w-0 overflow-visible">
                  <div className="shrink-0 w-3 h-3 bg-dark-gray-base rounded-sm mx-1" />
                </div>
                <p>Total</p>
              </div>
            ),
            width: 20,
            sorter: (item1, item2) => item1.total - item2.total,
          },
        ]}
        rowClassName="cursor-pointer"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setExpandedRecord(record);
              setExpanded(true);
            },
          };
        }}
      />
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={expanded}
        onClose={() => {
          setExpanded(false);
          // setExpandedRecord(null);
        }}
        onOpen={() =>
          expandedEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }
        header={
          <div className="flex flex-row items-center gap-3">
            <p>
              <span className="font-bold">Core Issue:</span>{" "}
              {expandedRecord?.name}
            </p>
          </div>
        }
        closeButton
      >
        {expandedRecord ? (
          <div className="relative max-h-full box-border -m-2 min-h-[310px] overflow-y-hidden">
            <div className="flex flex-col max-h-full box-border pb-4 px-2 mr-12 overflow-y-auto">
              <div className="flex flex-col gap-2 p-2">
                <p>Description</p>
                <p className="text-sm">{expandedRecord.description}</p>
              </div>
              <Divider className="my-0" />
              <div className="flex flex-row p-2">
                <p>Context</p>
              </div>
              <div className="overflow-y-auto h-full">
                <Table
                  className="grow filled-header"
                  sticky={true}
                  tableLayout="auto"
                  loading={!expandedRecord.documents}
                  dataSource={expandedRecord.documents.map((item, key) => ({
                    ...item,
                    key,
                  }))}
                  columns={[
                    {
                      dataIndex: "name",
                      key: "name",
                      title: "Name",
                    },
                    {
                      dataIndex: "issues",
                      key: "issues",
                      title: "Issues",
                      sorter: (item1, item2) => item1.issues - item2.issues,
                    },
                  ]}
                  size="small"
                  pagination={false}
                />
                <div ref={expandedEndRef} />
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
}
