import { arrayMove } from "@dnd-kit/sortable";
import { faBuilding, faClipboard } from "@fortawesome/free-regular-svg-icons";
import {
  faEdit,
  faGripVertical,
  faListCheck,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Breadcrumb,
  Button,
  ConfigProvider,
  Divider,
  Input,
  InputNumber,
  Skeleton,
  Table,
  Tabs,
} from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../../Contexts";
import Modal from "../../components/Modal";
import DraggableTable from "../../components/analytics/initiatives/DraggableTable";
import constants from "../../constants";
import agentAssistData from "../../data/Agent Assist.json";
import initiative_data from "../../data/initiative_data.json";
import initiative_data_demo from "../../data/initiative_data_demo.json";
import initiatives_data from "../../data/initiatives_data.json";
import requirements_data from "../../data/requirements_data.json";

export default function InitiativePage() {
  const { streamId, initiativeId } = useParams();
  const { guildId, activeModal, setActiveModal, openNotification } =
    useContext(SiteContext);
  const initiativeName = decodeURIComponent(initiativeId);

  const specialStream = ["1", "2"].includes(streamId);
  const data = specialStream
    ? streamId === "2"
      ? agentAssistData
      : initiatives_data
    : guildId === "1346577289890234438"
    ? initiative_data.initiatives
    : initiative_data_demo.initiatives;

  const [activeTab, setActiveTab] = useState(0);
  const [tableWidth, setTableWidth] = useState(null);
  const [processedRequirementData, setprocessedRequirementData] =
    useState(null);
  const [ranking, setRanking] = useState([]);
  const [editing, setEditing] = useState(false);
  const [
    proposedProcessedRequirementData,
    setProposedProcessedRequirementData,
  ] = useState(null);
  const [cutOff, setCutOff] = useState(true);
  const [cutOffCount, setCutOffCount] = useState(5);
  const [cutOffCountInput, setCutOffCountInput] = useState(5);
  const [arrInput, setArrInput] = useState(
    JSON.parse(localStorage.getItem(`requirement_arrs_stream_${streamId}`)) ||
      {}
  );
  const [arrActual, setArrActual] = useState(
    JSON.parse(localStorage.getItem(`requirement_arrs_stream_${streamId}`)) ||
      {}
  );
  const [weightsInput, setWeightsInput] = useState(
    {
      churn: 3.0,
      addressedNumber: 1.0,
      feasibility: 0,
      arr: 0.0025,
      ...JSON.parse(
        localStorage.getItem(`requirement_weights_stream_${streamId}`)
      ),
    } || {
      churn: 3.0,
      addressedNumber: 1.0,
      feasibility: 0,
      arr: 0.0025,
    }
  );
  const [weights, setWeights] = useState(
    {
      churn: 3.0,
      addressedNumber: 1.0,
      feasibility: 0,
      arr: 0.0025,
      ...JSON.parse(
        localStorage.getItem(`requirement_weights_stream_${streamId}`)
      ),
    } || {
      churn: 3.0,
      addressedNumber: 1.0,
      feasibility: 0,
      arr: 0.0025,
    }
  );
  const [addItemInput, setAddItemInput] = useState("");

  const navigate = useNavigate();

  const {
    title,
    description,
    top_companies: topCompanies,
    feedback_texts: feedbackTexts,
  } = data
    .map((item) => ({
      feedback_texts: [],
      top_companies: [],
      title: item.name,
      ...item,
    }))
    .filter(({ title }) => title === initiativeName)[0];

  const requirements = useRef(
    specialStream
      ? streamId === "2"
        ? agentAssistData.filter(({ title: _title }) => _title === title)[0]
            .requirements
        : requirements_data[title]
      : []
  );

  const highestTopCompaniesTotal = Math.max(
    ...topCompanies.map(({ count }) => count)
  );
  const highestRequirementsTotal =
    processedRequirementData &&
    Math.max(
      ...processedRequirementData.map(
        ({ "# of Feedback Items Addressed": addressedNumber }) =>
          addressedNumber
      )
    );

  const handleRect = useCallback((node) => {
    if (node) setTableWidth(node.offsetWidth);
  }, []);

  useEffect(() => {
    if (requirements.current)
      setprocessedRequirementData(
        requirements.current
          .map((item) => ({
            ...item,
            key: item.title,
            arr: arrActual[item.title],
            score:
              item["# of Feedback Items Addressed"] * weights.addressedNumber +
              item["Churn Risk Count"] * weights.churn +
              (!isNaN(item["Feasibility (dev hours)"])
                ? item["Feasibility (dev hours)"] * weights.feasibility
                : 0) +
              (arrActual[item.title] ? arrActual[item.title] * weights.arr : 0),
          }))
          .sort((item1, item2) => item2.score - item1.score)
          .map((item, index) => ({ ...item, rank: index + 1 }))
      );
  }, [requirements, weights, arrActual]);

  if (!title || !processedRequirementData)
    return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <Breadcrumb
          className="flex flex-row items-center -my-2"
          items={[
            { title: <a href={`/${guildId}/analytics/streams`}>Streams</a> },
            {
              title: (
                <a href={`/${guildId}/analytics/streams/${streamId}`}>
                  {
                    { 0: "Analytics", 1: "Analytics 1", 2: "Agent Assist" }[
                      streamId
                    ]
                  }
                </a>
              ),
            },
            {
              title: <h1 className="softer-text">{title}</h1>,
            },
          ]}
        />
      </div>
      <div className="flex flex-col gap-2 p-2">
        <h3>Description</h3>
        <p className="text-md">{description}</p>
      </div>
      <Divider className="my-0" />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: constants.colors.primary["light-blue"],
            fontFamily: constants.fonts["sans-header"][0],
          },
        }}
      >
        <Tabs
          tabBarExtraContent={
            <div className="flex flex-row items-center gap-2 mr-2">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: constants.colors.secondary,
                    fontFamily: constants.fonts["sans-header"],
                  },
                }}
              >
                {editing ? (
                  <>
                    <Button
                      key="editing-1"
                      className="font-sans flex flex-row items-center"
                      onClick={() => {
                        setEditing(false);
                        setProposedProcessedRequirementData(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      key="editing-2"
                      className="font-sans flex flex-row items-center"
                      type="primary"
                      onClick={() => {
                        setEditing(false);
                        setprocessedRequirementData(
                          proposedProcessedRequirementData
                        );
                      }}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      key="1"
                      className="font-sans flex flex-row items-center"
                      onClick={() => setActiveModal("modify_arr")}
                    >
                      Modify ARR
                    </Button>
                    <Button
                      key="2"
                      className="font-sans flex flex-row items-center"
                      onClick={() => setActiveModal("modify_weights")}
                    >
                      Modify Scoring
                    </Button>
                    <Button
                      key="3"
                      className="font-sans flex flex-row items-center"
                      onClick={() => setActiveModal("modify_limit")}
                    >
                      Modify Limit
                    </Button>
                    <Button
                      key="4"
                      className="font-sans flex flex-row items-center"
                      type="primary"
                      onClick={() => setActiveModal("add_item")}
                      icon={<FontAwesomeIcon icon={faPlus} />}
                    >
                      Add Item
                    </Button>
                    {activeTab === 0 && (
                      <Button
                        key="5"
                        className="font-sans flex flex-row items-center"
                        type="primary"
                        onClick={() => {
                          setEditing(true);
                          setProposedProcessedRequirementData(
                            processedRequirementData
                          );
                        }}
                        icon={<FontAwesomeIcon icon={faEdit} />}
                      >
                        Edit Rank
                      </Button>
                    )}
                  </>
                )}
              </ConfigProvider>
            </div>
          }
          items={[
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faListCheck} /> Requirements
                </span>
              ),
              key: 0,
            },
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faClipboard} /> Feedback
                </span>
              ),
              key: 1,
              disabled: editing,
            },
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faBuilding} /> Customers
                </span>
              ),
              key: 2,
              disabled: editing,
            },
          ]}
          onTabClick={(value) => {
            setActiveTab(value);
          }}
          className="w-full softer-text"
        />
      </ConfigProvider>
      {activeTab === 0 ? (
        editing ? (
          <div>
            <DraggableTable
              className="no-top"
              dataSource={proposedProcessedRequirementData}
              setDataSource={setProposedProcessedRequirementData}
              onDragEnd={({ active, over }) => {
                if (active.id !== over?.id) {
                  setProposedProcessedRequirementData((prev) => {
                    const activeIndex = prev.findIndex(
                      (i) => i.key === active.id
                    );
                    const overIndex = prev.findIndex((i) => i.key === over?.id);
                    let result = arrayMove(prev, activeIndex, overIndex);
                    return result.map((item, index) => ({
                      ...item,
                      rank: index + 1,
                    }));
                  });
                }
              }}
              columns={[
                {
                  key: "grip",
                  title: null,
                  render: () => (
                    <FontAwesomeIcon
                      className="text-shade-medium"
                      size="xl"
                      icon={faGripVertical}
                    />
                  ),
                },
                {
                  dataIndex: "rank",
                  key: "rank",
                  title: <p className="text-lg leading-4 font-bold">#</p>,
                  width: 20,
                  render: (rank) => (
                    <InputNumber
                      className="w-16 -my-2"
                      key={rank}
                      min={1}
                      max={proposedProcessedRequirementData.length}
                      defaultValue={rank}
                      onPressEnter={({ target }) => {
                        const value = Math.max(
                          Math.min(
                            parseInt(target.value),
                            proposedProcessedRequirementData.length
                          ),
                          1
                        );
                        if (rank !== value) {
                          setProposedProcessedRequirementData((prev) => {
                            const activeIndex = prev.findIndex(
                              (i) => i.rank === rank
                            );
                            const overIndex = prev.findIndex(
                              (i) => i.rank === value
                            );
                            let result = arrayMove(
                              prev,
                              activeIndex,
                              overIndex
                            );
                            return result.map((item, index) => ({
                              ...item,
                              rank: index + 1,
                            }));
                          });
                        }
                      }}
                      onStep={(inputValue) => {
                        const value = Math.max(
                          Math.min(
                            parseInt(inputValue),
                            proposedProcessedRequirementData.length
                          ),
                          1
                        );
                        if (rank !== value) {
                          setProposedProcessedRequirementData((prev) => {
                            const activeIndex = prev.findIndex(
                              (i) => i.rank === rank
                            );
                            const overIndex = prev.findIndex(
                              (i) => i.rank === value
                            );
                            let result = arrayMove(
                              prev,
                              activeIndex,
                              overIndex
                            );
                            return result.map((item, index) => ({
                              ...item,
                              rank: index + 1,
                            }));
                          });
                        }
                      }}
                    />
                  ),
                },
                {
                  dataIndex: "title",
                  key: "title",
                  title: "Requirement",
                  render: (name) => (
                    <div className="px-1 bg-white/50 dark:bg-dark-white/50 leading-5 rounded w-fit">
                      {name}
                    </div>
                  ),
                },
                {
                  dataIndex: "Churn Risk Count",
                  key: "Churn Risk Count",
                  title: "Churn",
                  width: 20,
                },
                {
                  dataIndex: "# of Feedback Items Addressed",
                  key: "# of Feedback Items Addressed",
                  title: <p className="whitespace-nowrap"># Addressed</p>,
                  width: 20,
                },
                {
                  dataIndex: "% of Total Feedback Addressed",
                  key: "% of Total Feedback Addressed",
                  title: <p className="whitespace-nowrap">% Addressed</p>,
                  width: 20,
                  render: (value) => parseFloat(value).toFixed(2),
                },
                {
                  dataIndex: "Feasibility (dev hours)",
                  key: "Feasibility (dev hours)",
                  title: (
                    <p className="whitespace-nowrap">Feasibility (dev hours)</p>
                  ),
                  width: 20,
                },
                ...(processedRequirementData.filter(
                  (item) => arrActual[item.title]
                ).length
                  ? [
                      {
                        dataIndex: "arr",
                        key: "arr",
                        title: <p className="whitespace-nowrap">ARR</p>,
                        width: 20,
                      },
                    ]
                  : []),
                {
                  dataIndex: "score",
                  key: "score",
                  title: <p className="whitespace-nowrap">Score</p>,
                  width: 20,
                },
                {
                  key: "delete",
                  title: null,
                  render: (_, __, index) => (
                    <Button
                      className="-my-2"
                      danger
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() =>
                        setProposedProcessedRequirementData((data) => [
                          ...data.slice(0, index),
                          ...data
                            .slice(index + 1)
                            .map((item) => ({ ...item, rank: item.rank - 1 })),
                        ])
                      }
                    />
                  ),
                },
              ]}
            />
          </div>
        ) : (
          <div>
            <Table
              className="no-top"
              ref={handleRect}
              tableLayout="auto"
              dataSource={processedRequirementData.slice(
                0,
                cutOff ? cutOffCount : undefined
              )}
              columns={[
                {
                  dataIndex: "rank",
                  key: "rank",
                  title: <p className="text-lg leading-4 font-bold">#</p>,
                  width: 20,
                  render: (
                    rank,
                    {
                      "# of Feedback Items Addressed": addressedNumber,
                      "Churn Risk Count": churn,
                    }
                  ) => (
                    <div className="flex flex-row items-center gap-0 w-full h-10 -my-4">
                      <div className="w-0 z-10 flex flex-row justify-start">
                        <div className="m-2.5 text-lg font-bold">{rank}</div>
                      </div>
                      <div
                        className="absolute left-0 top-0 bottom-0 py-2 px-4 box-border"
                        style={{ width: tableWidth || 0 }}
                      >
                        <div
                          className={`bg-dark-gray-base dark:bg-dark-gray-medium h-full rounded-lg ${
                            rank > cutOffCount ? "opacity-50" : "opacity-100"
                          }`}
                          style={{
                            width:
                              (addressedNumber / highestRequirementsTotal) *
                                100 +
                              "%",
                          }}
                        >
                          <div
                            className={`bg-red-dark h-full rounded-lg ${
                              rank > cutOffCount ? "opacity-75" : "opacity-100"
                            }`}
                            style={{
                              width: (churn / addressedNumber) * 100 + "%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                  sorter: (item1, item2) => item1.rank - item2.rank,
                },
                {
                  dataIndex: "title",
                  key: "title",
                  title: "Requirement",
                  render: (name) => (
                    <div className="px-1 bg-white/50 dark:bg-dark-white/50 leading-5 rounded w-fit">
                      {name}
                    </div>
                  ),
                },
                {
                  dataIndex: "Churn Risk Count",
                  key: "Churn Risk Count",
                  title: (
                    <div className="flex flex-row items-center gap-0">
                      <div className="flex flex-row justify-end w-0 overflow-visible">
                        <div className="shrink-0 w-3 h-3 bg-red-dark rounded-sm mx-1" />
                      </div>
                      <p>Churn</p>
                    </div>
                  ),
                  width: 20,
                  sorter: (item1, item2) =>
                    item1["Churn Risk Count"] - item2["Churn Risk Count"],
                },
                {
                  dataIndex: "# of Feedback Items Addressed",
                  key: "# of Feedback Items Addressed",
                  title: (
                    <div className="flex flex-row items-center gap-0">
                      <div className="flex flex-row justify-end w-0 overflow-visible">
                        <div className="shrink-0 w-3 h-3 bg-dark-gray-base dark:bg-dark-gray-medium rounded-sm mx-1" />
                      </div>
                      <p className="whitespace-nowrap"># Addressed</p>
                    </div>
                  ),
                  width: 20,
                  sorter: (item1, item2) =>
                    item1["# of Feedback Items Addressed"] -
                    item2["# of Feedback Items Addressed"],
                },
                {
                  dataIndex: "% of Total Feedback Addressed",
                  key: "% of Total Feedback Addressed",
                  title: <p className="whitespace-nowrap">% Addressed</p>,
                  width: 20,
                  sorter: (item1, item2) =>
                    item1["% of Total Feedback Addressed"] -
                    item2["% of Total Feedback Addressed"],
                  render: (value) => parseFloat(value).toFixed(2),
                },
                {
                  dataIndex: "Feasibility (dev hours)",
                  key: "Feasibility (dev hours)",
                  title: (
                    <p className="whitespace-nowrap">Feasibility (dev hours)</p>
                  ),
                  width: 20,
                  sorter: (item1, item2) =>
                    item1["Feasibility (dev hours)"] -
                    item2["Feasibility (dev hours)"],
                },
                ...(processedRequirementData.filter(
                  (item) => arrActual[item.title]
                ).length
                  ? [
                      {
                        dataIndex: "arr",
                        key: "arr",
                        title: <p className="whitespace-nowrap">ARR</p>,
                        width: 20,
                        sorter: (item1, item2) => item1.arr - item2.arr,
                      },
                    ]
                  : []),
                {
                  dataIndex: "score",
                  key: "score",
                  title: <p className="whitespace-nowrap">Score</p>,
                  width: 20,
                  sorter: (item1, item2) => item1.score - item2.score,
                },
              ]}
              rowClassName="cursor-pointer"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate(
                      `/${guildId}/analytics/streams/${streamId}/${initiativeId}/${encodeURIComponent(
                        record.title
                      )}`
                    );
                  },
                };
              }}
            />
            <div className="flex flex-row justify-end w-full">
              <Button
                type="text"
                onClick={() => setCutOff((cutOff) => !cutOff)}
              >
                {cutOff ? "Show Extra Items" : "Hide Extra Items"}
              </Button>
            </div>
          </div>
        )
      ) : activeTab === 1 ? (
        <>
          <Table
            className="no-top"
            sticky={true}
            tableLayout="auto"
            loading={!feedbackTexts}
            dataSource={feedbackTexts.map((text, index) => ({
              text,
              key: index,
            }))}
            columns={[
              {
                dataIndex: "text",
                key: "text",
                title: "Feedback",
                render: (feedback) => (
                  <div className="p-1 leading-5 bg-black/10 dark:bg-dark-black/10 border-solid border-px border-black/25 dark:border-dark-black/25 rounded-lg">
                    <span className="text-shade-medium font-bold mr-px">“</span>
                    {feedback}
                    <span className="text-shade-medium font-bold ml-px">”</span>
                  </div>
                ),
              },
            ]}
            pagination={false}
          />
        </>
      ) : activeTab === 2 ? (
        <Table
          className="no-top"
          ref={handleRect}
          tableLayout="auto"
          dataSource={topCompanies
            .map((item) => ({ ...item, key: item.company_name }))
            .sort((item1, item2) => item2.count - item1.count)}
          columns={[
            {
              dataIndex: "company_name",
              key: "company_name",
              title: "Customer",
              render: (company_name, { count }) => (
                <div className="flex flex-row items-center gap-0 w-full h-10 -my-4">
                  <div className="w-0 z-10 flex flex-row justify-start">
                    <div className="shrink-0 m-2.5 px-1 bg-white/50 dark:bg-dark-white/50 leading-5 rounded">
                      {company_name}
                    </div>
                  </div>
                  <div
                    className="absolute left-0 top-0 bottom-0 py-2 px-4 box-border"
                    style={{ width: tableWidth || 0 }}
                  >
                    <div
                      className="bg-primary-light dark:bg-primary-medium h-full rounded-lg"
                      style={{
                        width: (count / highestTopCompaniesTotal) * 100 + "%",
                      }}
                    />
                  </div>
                </div>
              ),
            },
            {
              dataIndex: "count",
              key: "count",
              title: "Feedback Count",
              width: 150,
              sorter: (item1, item2) => item1.count - item2.count,
            },
          ]}
          pagination={false}
        />
      ) : null}
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={activeModal === "modify_arr"}
        onClose={() => {
          setActiveModal(null);
          setArrInput(
            JSON.parse(
              localStorage.getItem(`requirement_arrs_stream_${streamId}`)
            ) || arrActual
          );
        }}
        header="Modify ARRs"
        closeButton
      >
        {processedRequirementData ? (
          <div className="flex flex-col gap-2 h-full overflow-hidden">
            <div className="overflow-y-auto">
              <Table
                className="grow filled-header"
                sticky={true}
                tableLayout="auto"
                dataSource={processedRequirementData.map((item, key) => ({
                  ...item,
                  key,
                }))}
                columns={[
                  {
                    dataIndex: "title",
                    key: "title",
                    title: "Requirement",
                  },
                  {
                    key: "arr",
                    title: "ARR",
                    width: 150,
                    render: (_, { title: requirement }) => (
                      <InputNumber
                        value={arrInput[requirement]}
                        onChange={(value) =>
                          setArrInput((arrInput) => ({
                            ...arrInput,
                            [requirement]: value || undefined,
                          }))
                        }
                      />
                    ),
                  },
                ]}
                size="small"
                pagination={false}
              />
            </div>
            <div className="flex flex-row-reverse w-full">
              <Button
                type="primary"
                onClick={() => {
                  localStorage.setItem(
                    `requirement_arrs_stream_${streamId}`,
                    JSON.stringify(arrInput)
                  );
                  setArrActual(arrInput);
                  openNotification(
                    "success",
                    "ARRs Saved",
                    "The inputted ARR values have been saved to local storage."
                  );
                  setActiveModal(null);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={activeModal === "modify_weights"}
        onClose={() => {
          setActiveModal(null);
          setWeightsInput(
            JSON.parse(
              localStorage.getItem(`requirement_weights_stream_${streamId}`)
            ) || weights
          );
        }}
        header="Modify Scoring Formula"
        closeButton
      >
        {processedRequirementData ? (
          <div className="flex flex-col gap-2 h-full overflow-hidden">
            <div className="overflow-y-auto">
              <Table
                className="grow filled-header"
                sticky={true}
                tableLayout="auto"
                dataSource={Object.entries(weightsInput).map(
                  ([value, weight], key) => ({ value, weight, key })
                )}
                columns={[
                  {
                    dataIndex: "value",
                    key: "value",
                    title: "Value",
                    render: (value) =>
                      ({
                        churn: "Churn",
                        addressedNumber: "# Addressed",
                        feasibility: "Feasibility (dev hours)",
                        arr: "ARR",
                      }[value]),
                  },
                  {
                    key: "weight",
                    title: "Weight",
                    width: 150,
                    render: (_, { value }) => (
                      <InputNumber
                        value={weightsInput[value]}
                        onChange={(inputValue) =>
                          setWeightsInput((weightsInput) => ({
                            ...weightsInput,
                            [value]: inputValue,
                          }))
                        }
                      />
                    ),
                  },
                ]}
                size="small"
                pagination={false}
              />
            </div>
            <div className="flex flex-row-reverse w-full">
              <Button
                type="primary"
                onClick={() => {
                  localStorage.setItem(
                    `requirement_weights_stream_${streamId}`,
                    JSON.stringify(weightsInput)
                  );
                  setWeights(weightsInput);
                  openNotification(
                    "success",
                    "Scoring Formula Saved",
                    "The inputted scoring values have been saved to local storage."
                  );
                  setActiveModal(null);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={activeModal === "modify_limit"}
        onClose={() => {
          setActiveModal(null);
          setCutOffCountInput(cutOffCount);
        }}
        header="Modify Item Limit"
        closeButton
      >
        <div className="flex flex-col gap-2 h-full overflow-hidden">
          <InputNumber
            className="w-full"
            value={cutOffCountInput}
            onChange={setCutOffCountInput}
          />
          <div className="flex flex-row-reverse w-full">
            <Button
              type="primary"
              onClick={() => {
                setCutOffCount(cutOffCountInput);
                openNotification(
                  "success",
                  "Item Limit Saved",
                  "The inputted item limit value has been applied."
                );
                setActiveModal(null);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={activeModal === "add_item"}
        onClose={() => {
          setActiveModal(null);
          setAddItemInput("");
        }}
        header="Add Requirement"
        closeButton
      >
        <div className="flex flex-col gap-2 w-96 overflow-hidden">
          <label className="flex flex-col gap-2">
            <p className="text-sm">Requirement Name:</p>
            <Input
              className="w-full"
              value={addItemInput}
              onChange={({ target }) => setAddItemInput(target.value)}
            />
          </label>
          <p className="text-sm font-normal softer-text">
            New initiatives must be processed to find feedback and will take
            some time to appear in the list.
          </p>
          <div className="flex flex-row-reverse w-full">
            <Button
              type="primary"
              onClick={() => {
                setAddItemInput("");
                openNotification(
                  "success",
                  "New Item Processing",
                  "The inputted item is being processed and will appear shortly."
                );
                setActiveModal(null);
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
