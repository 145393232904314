import {
  faComments,
  faFlag,
  faLightbulb,
  faMessage,
  faObjectGroup,
  faRectangleList,
} from "@fortawesome/free-regular-svg-icons";
import {
  faChartLine,
  faClipboardList,
  faClockRotateLeft,
  faGauge,
  faGavel,
  faPenClip,
  faPeopleArrows,
  faScaleBalanced,
  faScrewdriverWrench,
  faSliders,
  faTimeline,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Skeleton, notification } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { io } from "socket.io-client";
import { AccountContext, SiteContext, ThemeContext } from "../Contexts";
import defaultPermissions from "../defaultPermissions";
import NotFound from "../pages/NotFound";
import APIManager from "../scripts/APIManager";
import Card from "./Card";
import Header from "./Header";

export default function SiteContainer() {
  const { signedIn } = useContext(AccountContext);
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const { guildId } = useParams();
  const [currentGuildId, setCurrentGuildId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [subscribed, setSubscribed] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [discordLinked, setDiscordLinked] = useState(null);
  // const [hasAccess, setHasAccess] = useState(null);
  const [hasGuildAccess, setHasGuildAccess] = useState(null);
  const [invited, setInvited] = useState(null);
  const [navTree, setNavTree] = useState([]);
  const [guildName, setGuildName] = useState(null);
  const [bots, setBots] = useState(null);
  const [rules, setRules] = useState(null);
  const [guilds, setGuilds] = useState(null);
  const [error, setError] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [redirectURI, setRedirectURI] = useState(null);
  const [refreshReportsImmediately, setRefreshReportsImmediately] =
    useState(false);
  const [refreshRolesImmediately, setRefreshRolesImmediately] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [questionAnswerResponse, setQuestionAnswerResponse] = useState(null);
  const [navExpanded, setNavExpanded] = useState(
    window.localStorage.getItem("expanded_nav") !== null
      ? window.localStorage.getItem("expanded_nav") === "true"
      : false
  );
  const navigate = useNavigate();
  const location = useLocation();

  const openNotification = useCallback(
    (type, title, message, duration = 5, key = null) => {
      api[type]({
        message: (
          <h1
            className={`${
              darkMode ? "text-dark-gray-base" : "text-gray-base"
            } font-sans`}
          >
            {title}
          </h1>
        ),
        description: (
          <p
            className={`${
              darkMode ? "text-dark-gray-base" : "text-gray-base"
            } text-sm font-normal font-sans`}
          >
            {message}
          </p>
        ),
        placement: "bottomRight",
        duration,
        key,
      });
    },
    [api, darkMode]
  );

  const closeNotification = useCallback((key) => api.destroy(key), [api]);

  const requestGuild = useCallback(() => {
    setGuildName(null);
    setBots(null);
    APIManager.sendRequest(
      "get_guild",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      setInvited(data.invited);
      setGuildName(data.name);
      if (data.error) {
        setHasGuildAccess(data.has_access);
        setError(data.error);
      } else {
        setBots(data.bots);
        setHasGuildAccess(data.has_access);
        setError(null);
      }
    });
  }, [guildId]);

  const requestRules = useCallback(() => {
    setRules(null);
    APIManager.sendRequest(
      "get_rules",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.code && data.code === "NoPermission") setRules([]);
      else if (data.error) setError(`error: ${data.error}`);
      else setRules(data.rules);
    });
  }, [guildId]);

  const requestGuilds = useCallback((clearFirst = true) => {
    if (clearFirst) setGuilds(null);
    APIManager.sendRequest("get_guilds", {}, true).then((data) => {
      if (data.code === "NoSubscription") {
        setSubscribed(false);
      } else if (data.error) setError(`error: ${data.error}`);
      else {
        setGuilds(data.guilds);
        setIsAdmin(data.admin);
        setDiscordLinked(data.discord_linked);
        //setHasAccess(data.has_access);
        setSubscribed(data.subscribed);
      }
    });
  }, []);

  const requestPermissions = useCallback(
    (clearFirst = true) => {
      if (clearFirst) setPermissions(null);
      APIManager.sendRequest(
        "get_permissions",
        { guild_id: guildId },
        true
      ).then((data) => {
        if (data.error) setError(`error: ${data.error}`);
        else if (data.admin || data.owner) {
          let adminPermissions = {};
          Object.keys(defaultPermissions).forEach((key) => {
            adminPermissions[key] = true;
          });
          setPermissions(adminPermissions);
        } else if (data.permissions === null) {
          setPermissions(defaultPermissions);
        } else {
          setPermissions(data.permissions);
        }
      });
    },
    [guildId]
  );

  const acceptInvite = useCallback(() => {
    APIManager.sendRequest(
      "accept_access_user_invite",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.success) {
        requestGuild();
        requestPermissions();
      }
    });
  }, [guildId, requestGuild, requestPermissions]);

  const goToCheckout = useCallback(() => {
    APIManager.sendRequest(
      "create_checkout_session",
      {
        return_address: window.location.href,
      },
      true
    ).then((data) => {
      if (data.success) window.location.href = data.url;
    });
  }, []);

  useEffect(
    () =>
      setRedirectURI(
        window.location.protocol +
          "//" +
          window.location.host +
          (location.pathname === "/"
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname.replace(location.pathname, "")) +
          "/home/discord_auth"
      ),
    [location]
  );

  useEffect(
    () => window.localStorage.setItem("expanded_nav", navExpanded),
    [navExpanded]
  );

  useEffect(() => setError(null), [location]);

  useEffect(() => {
    let url = process.env.REACT_APP_API_URL.split("/", 3).join("/");
    let socket = io(url, {
      path: process.env.REACT_APP_API_WS_SUBPATH || undefined,
    });

    socket.emit("listen_guild", guildId.toString());

    socket.on("send_answer", (value) => {
      setQuestionAnswerResponse(value);
    });

    socket.on("connect_error", (err) => {
      console.log("WebSocket connection error:", err);
    });

    socket.on("new_report", (value) => {
      openNotification(
        "info",
        "New Report",
        "The list of reports has been updated."
      );
      setRefreshReportsImmediately(true);
    });

    socket.on("role_change", (value) => {
      openNotification(
        "info",
        "Role Change",
        "The list of roles has been updated."
      );
      setRefreshRolesImmediately(true);
    });

    return () => socket.disconnect();
  }, [guildId, openNotification]);

  useEffect(() => {
    if (!bots || !permissions || (bots.includes("moderation") && !rules))
      return;

    let tree = [];
    if (bots && bots.includes("moderation")) {
      tree.push({
        name: "Moderation",
        id: "moderation",
        icon: faMessage,
        routes: [
          { name: "Dashboard", id: "dashboard", icon: faGauge },
          { name: "Universal Settings", id: "universal", icon: faSliders },
          { name: "Incidents", id: "incidents", icon: faFlag },
          { name: "Actions", id: "actions", icon: faClockRotateLeft },
          {
            id: "basic_rules",
            name: "Basic Rules",
            elements: rules
              .filter((rule) => rule.is_basic)
              .map((rule) => ({
                ...rule,
                id: `rule/${encodeURIComponent(rule.id)}`,
              })),
            icon: faGavel,
          },
          {
            id: "custom_rules",
            name: "Custom Rules",
            elements: [
              { name: "Add Rule", id: "add_rule", add_rule_button: true },
              ...rules
                .filter((rule) => !rule.is_basic)
                .map((rule) => ({
                  ...rule,
                  id: `rule/${encodeURIComponent(rule.id)}`,
                })),
            ],
            icon: faScaleBalanced,
          },
        ],
      });
    }
    if (bots && bots.includes("analytics")) {
      tree.push({
        name: "Analytics",
        id: "analytics",
        icon: faChartLine,
        routes: [
          { name: "Feedback Spotlights", id: "bug_reports", icon: faLightbulb },
          {
            name: "Summary Dashboard",
            id: "summary_dashboard",
            icon: faRectangleList,
          },
          // { name: "Q and A", id: "q_and_a" },
          ...(["1346577289890234438", "1346577334282485792"].includes(guildId)
            ? [
                {
                  name: "Core Issues",
                  id: "core_issues",
                  icon: faClipboardList,
                },
                {
                  name: "Initiatives",
                  id: "streams",
                  icon: faScrewdriverWrench,
                  elements: [
                    { name: "Analytics", id: "streams/0" },
                    ...(guildId === "1346577289890234438"
                      ? [
                          { name: "Analytics 1", id: "streams/1" },
                          { name: "Agent Assist", id: "streams/2" },
                        ]
                      : []),
                    {
                      name: "Add Stream",
                      id: "add_stream",
                      add_stream_button: true,
                    },
                  ],
                },
              ]
            : []),
          { name: "Top Issues", id: "top_issues", icon: faObjectGroup },
          { name: "Messages", id: "messages", icon: faComments },
          { name: "Interactions", id: "interactions", icon: faPeopleArrows },
          { name: "Timeline", id: "timeline", icon: faTimeline },
          // { name: "Moderation", id: "moderation" },
        ],
      });
    }
    if (permissions["View Suggestions"]) {
      tree.push({
        name: "Manage",
        id: "manage",
        icon: faUserTie,
        routes: [{ name: "Suggestions", id: "suggestions", icon: faPenClip }],
      });
    }

    setNavTree(tree);

    return () => setNavTree([]);
  }, [rules, bots, permissions, guildId]);

  useEffect(() => {
    if (signedIn && bots && bots.includes("moderation")) requestRules();
  }, [signedIn, bots, requestRules]);

  useEffect(() => {
    if (signedIn) {
      requestGuild();
      requestPermissions();
    }
  }, [signedIn, requestGuild, requestPermissions]);

  useEffect(() => {
    if (signedIn && hasGuildAccess) requestGuilds();
  }, [signedIn, hasGuildAccess, requestGuilds]);

  useEffect(() => {
    if (signedIn === false)
      navigate({
        pathname: "/login",
        search: `?${createSearchParams({ to_guild: guildId })}&mode=signUp`,
      });
  }, [navigate, signedIn, guildId]);

  // useEffect(() => {
  //   if (hasGuildAccess !== null && !hasGuildAccess && !invited)
  //     navigate("/", { replace: true });
  // }, [hasGuildAccess, invited, navigate]);

  useEffect(() => {
    setCurrentGuildId(guildId);
    return () => setCurrentGuildId(null);
  }, [guildId, setCurrentGuildId]);

  useEffect(() => {
    if (
      currentGuildId === guildId &&
      bots &&
      permissions &&
      location.pathname.split("/").filter((i) => i).length === 1
    ) {
      if (bots.includes("moderation") && permissions["View Moderation"])
        navigate("moderation", { replace: true });
      else if (bots.includes("analytics") && permissions["View Analytics"])
        navigate("analytics", { replace: true });
    }
  }, [bots, permissions, location, guildId, currentGuildId, navigate]);

  useEffect(() => {
    if (
      location.pathname.split("/").filter((i) => i).length > 1 &&
      bots &&
      permissions
    ) {
      if (location.pathname.split("/").filter((i) => i)[1] === "moderation") {
        if (!bots.includes("moderation")) setError("Moderation bot not active");
        else if (!permissions["View Moderation"])
          setError("You do not have permission to view Moderation");
      } else if (
        location.pathname.split("/").filter((i) => i)[1] === "analytics"
      ) {
        if (!bots.includes("analytics")) setError("Analytics bot not active");
        else if (!permissions["View Analytics"])
          setError("You do not have permission to view Analytics");
      }
    }
  }, [bots, permissions, location]);

  if (invited)
    return (
      <div className="static min-h-screen flex flex-col items-center justify-center bg-white dark:bg-dark-white">
        {guildName ? (
          <Card className="flex flex-col gap-10 p-10">
            <div className="flex flex-col gap-3">
              <h1>
                You are invited to:{" "}
                <span className="whitespace-nowrap">{guildName}</span>
              </h1>
            </div>
            <div className="flex flex-row-reverse gap-3">
              <Button
                type="primary"
                size="large"
                className="w-32 font-sans"
                onClick={() => {
                  acceptInvite();
                }}
              >
                Accept
              </Button>
              <Button
                size="large"
                className="w-32 font-sans"
                onClick={() => {
                  navigate("/home");
                }}
              >
                Go Back
              </Button>
            </div>
          </Card>
        ) : (
          <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />
        )}
      </div>
    );

  if (error)
    return (
      <NotFound
        error={error}
        guildId={hasGuildAccess ? guildId : undefined}
        screenHeight
      />
    );

  return (
    <SiteContext.Provider
      value={{
        reloadNav: requestRules,
        openNotification,
        closeNotification,
        darkMode,
        setDarkMode,
        guildId,
        guilds,
        rules,
        refreshRules: requestRules,
        activeModal,
        setActiveModal,
        refreshRolesImmediately,
        setRefreshRolesImmediately,
        refreshReportsImmediately,
        setRefreshReportsImmediately,
        permissions,
        questionAnswerResponse,
      }}
    >
      <div
        className="static relative min-h-screen flex flex-row bg-white dark:bg-black overflow-x-hidden
         bg-gradient-to-tr from-[#ffdc00]/20 via-transparent to-[#0099ff]/20 bg-fixed"
      >
        <Header
          tree={navTree}
          title={guildName}
          guildId={guildId}
          guilds={guilds}
          setActiveModal={setActiveModal}
          isAdmin={isAdmin}
          subscribed={subscribed}
          discordLinked={discordLinked}
          redirectURI={redirectURI}
          goToCheckout={goToCheckout}
          permissions={permissions}
          bigSize={"w-80"}
          smallSize={"w-12"}
          expandLock={navExpanded}
          setExpandLock={setNavExpanded}
          className="fixed box-border h-full border-0 border-r border-solid border-black/10 dark:border-[#303030] transition-all"
        />
        <div
          className={`grow shrink flex flex-col w-full w-full w-fit box-border overflow-y-hidden transition-all ${
            navExpanded ? "sm:ml-80" : "sm:ml-12"
          }`}
        >
          <div
            className="relative grow flex flex-col gap-0 w-full w-fit
            box-border px-2 py-1"
          >
            {bots ? (
              <Outlet />
            ) : (
              <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />
            )}
          </div>
        </div>
      </div>
      {contextHolder}
    </SiteContext.Provider>
  );
}
