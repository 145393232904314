import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  theme,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useState } from "react";
import Card from "../../components/Card";
import Eye from "../../components/Eye";
import LandingFooter from "../../components/landing/LandingFooter";
import LandingHeader from "../../components/landing/LandingHeader";
import constants from "../../constants";
import APIManager from "../../scripts/APIManager";
import { Link } from "react-router-dom";

export default function Contact() {
  const [sent, setSent] = useState(false);
  const [name, setName] = useState(null);
  const [nameStatus, setNameStatus] = useState(null);
  const [nameWarning, setNameWarning] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailStatus, setEmailStatus] = useState(null);
  const [emailWarning, setEmailWarning] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageStatus, setMessageStatus] = useState(null);
  const [messageWarning, setMessageWarning] = useState(null);
  const [requestDemo, setRequestDemo] = useState(true);

  const sendEmail = useCallback(async () => {
    return APIManager.sendRequest(
      "send_demo_email",
      {
        name,
        email,
        message,
        request_demo: requestDemo,
      },
      false
    ).then((data) => {
      if (data.success) setSent(true);
    });
  }, [name, email, message, requestDemo]);

  const tryToSend = useCallback(async () => {
    let readyToSend = true;
    if (name === null || name === "") {
      readyToSend = false;
      setNameStatus("error");
      setNameWarning("This field cannot be blank.");
    }
    if (email === null || email === "") {
      readyToSend = false;
      setEmailStatus("error");
      setEmailWarning("This field cannot be blank.");
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]+$/.test(email)) {
      readyToSend = false;
      setEmailStatus("error");
      setEmailWarning("Invalid email address.");
    }
    if (message === null || message === "") {
      readyToSend = false;
      setMessageStatus("error");
      setMessageWarning("This field cannot be blank.");
    }

    if (readyToSend) sendEmail();
  }, [name, email, message, sendEmail]);

  if (sent)
    return (
      <div className="relative no-zoom">
        <div className="-z-10 absolute inset-0 bg-white" />

        <ConfigProvider
          theme={{
            algorithm: theme.defaultAlgorithm,
            token: {
              colorPrimary: constants.colors["secondary"],
            },
          }}
        >
          <div className="min-h-screen flex flex-col">
            <LandingHeader
              simple
              landing
              className="fixed box-border h-12 sm:h-16 w-full"
            />

            <div className="flex flex-col items-center justify-center box-border py-24 sm:py-24 sm:min-h-screen bg-[url('/src/images/landing/blobbackground2.png')] bg-cover">
              <div className="h-12 sm:h-0" />
              <Card
                noMode
                className="self-stretch flex flex-col justify-center sm:self-center sm:w-96 h-64 mx-8 shadow-[0_0_5px_5px_rgba(0,0,0,0.1)]"
              >
                <div className="flex flex-col items-center">
                  <p className="font-landing text-center text-black text-xl p-12">
                    Thank you for reaching out to us. Someone will contact you
                    shortly.
                  </p>
                  <Link
                    className="font-landing text-black font-semibold underline"
                    to="/"
                  >
                    Back to Home
                  </Link>
                </div>
              </Card>
            </div>

            <LandingFooter className="mt-[0px]" noDemo />
          </div>
        </ConfigProvider>
      </div>
    );

  return (
    <div className="relative no-zoom">
      <div className="-z-10 absolute inset-0 bg-white" />
      <div className="-z-10 absolute w-full flex flex-row justify-center top-[50px]">
        <div className="landing-bg h-[400px] w-[400px]" />
      </div>

      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: constants.colors["secondary"],
          },
        }}
      >
        <div className="min-h-screen flex flex-col">
          <LandingHeader
            simple
            landing
            className="fixed box-border h-12 sm:h-16 w-full"
          />

          <div className="h-16 sm:h-0" />
          <Form className="flex flex-col items-center justify-center gap-4 sm:min-h-screen sm:py-20">
            <div className="flex flex-row items-center gap-2">
              <Eye height={24} width={30} />
              <p className="font-landing-header text-xl text-secondary font-semibold">
                Contact Us
              </p>
            </div>
            <Card
              noMode
              className="h-fit bg-black/10 border-black/20 border-[1px] border-solid self-stretch sm:self-center sm:w-96 mx-8"
            >
              <div className="flex flex-col gap-1">
                <Tooltip title={nameWarning} color="red">
                  <label className="flex flex-col gap-px">
                    <p className="font-landing text-black">Name</p>
                    <Input
                      name="name"
                      title="Name"
                      onFocus={() => {
                        setNameStatus(null);
                        setNameWarning(null);
                      }}
                      status={nameStatus}
                      size="large"
                      placeholder="Enter Name"
                      onChange={({ target }) => setName(target.value)}
                      value={name}
                    />
                  </label>
                </Tooltip>
                <Tooltip title={emailWarning} color="red">
                  <label className="flex flex-col gap-px">
                    <p className="font-landing text-black">Email</p>
                    <Input
                      name="email"
                      title="Email"
                      onFocus={() => {
                        setEmailStatus(null);
                        setEmailWarning(null);
                      }}
                      status={emailStatus}
                      size="large"
                      placeholder="Enter Email"
                      onChange={({ target }) => setEmail(target.value)}
                      value={email}
                    />
                  </label>
                </Tooltip>
                <Tooltip title={messageWarning} color="red">
                  <label className="flex flex-col gap-px">
                    <p className="font-landing text-black">Message</p>
                    <TextArea
                      name="message"
                      title="Message"
                      autoSize={{ minRows: 4, maxRows: 6 }}
                      onFocus={() => {
                        setMessageStatus(null);
                        setMessageWarning(null);
                      }}
                      status={messageStatus}
                      size="large"
                      onChange={({ target }) => setMessage(target.value)}
                      value={message}
                    />
                  </label>
                </Tooltip>
                <Tooltip title={messageWarning} color="red">
                  <label className="flex flex-col gap-px">
                    <p className="font-landing text-black">Message</p>
                    <Checkbox
                      onChange={({ target }) => setRequestDemo(target.checked)}
                      checked={requestDemo}
                    >
                      <span className="font-landing">Request Demo</span>
                    </Checkbox>
                  </label>
                </Tooltip>
                <Button
                  type="primary"
                  className="self-center w-[80%] h-16 bg-[#FFD86D] my-4"
                  onClick={tryToSend}
                  htmlType="submit"
                >
                  <span className="font-landing-header capitalize text-2xl font-semibold text-black">
                    Send
                  </span>
                </Button>
              </div>
            </Card>
          </Form>

          <LandingFooter noDemo />
        </div>
      </ConfigProvider>
    </div>
  );
}
