import { faBuilding, faClipboard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Breadcrumb,
  ConfigProvider,
  Divider,
  Skeleton,
  Table,
  Tabs,
} from "antd";
import { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { SiteContext } from "../../Contexts";
import constants from "../../constants";
import agentAssistData from "../../data/Agent Assist.json";
import requirements_data from "../../data/requirements_data.json";

export default function RequirementPage() {
  const { streamId, initiativeId, requirementId } = useParams();
  const { guildId } = useContext(SiteContext);
  const initiativeName = decodeURIComponent(initiativeId);
  const requirementName = decodeURIComponent(requirementId);
  const data =
    streamId === "2"
      ? agentAssistData.filter(({ title }) => title === initiativeName)[0]
          .requirements
      : requirements_data[initiativeName];
  const [activeTab, setActiveTab] = useState(0);
  const [tableWidth, setTableWidth] = useState(null);

  const { title, description } = data.filter(
    ({ title }) => title === requirementName
  )[0];
  const topCompanies = [];
  const feedbackTexts = [];

  const highestTotal = Math.max(...topCompanies.map(({ count }) => count));

  const handleRect = useCallback((node) => {
    if (node) setTableWidth(node.offsetWidth);
  }, []);

  if (!title)
    return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <Breadcrumb
          className="flex flex-row items-center -my-2"
          items={[
            { title: <a href={`/${guildId}/analytics/streams`}>Streams</a> },
            {
              title: (
                <a href={`/${guildId}/analytics/streams/${streamId}`}>
                  {
                    { 0: "Analytics", 1: "Analytics 1", 2: "Agent Assist" }[
                      streamId
                    ]
                  }
                </a>
              ),
            },
            {
              title: (
                <a
                  className="max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis"
                  href={`/${guildId}/analytics/streams/${streamId}/${initiativeId}`}
                >
                  {initiativeName}
                </a>
              ),
            },
            {
              title: <h1 className="softer-text">{title}</h1>,
            },
          ]}
        />
      </div>
      <div className="flex flex-col gap-2 p-2">
        <h3>Description</h3>
        <p className="text-md">{description}</p>
      </div>
      <Divider className="my-0" />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: constants.colors.primary["light-blue"],
            fontFamily: constants.fonts["sans-header"][0],
          },
        }}
      >
        <Tabs
          items={[
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faClipboard} /> Feedback
                </span>
              ),
              key: 0,
            },
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faBuilding} /> Customers
                </span>
              ),
              key: 1,
            },
          ]}
          onTabClick={(value) => {
            setActiveTab(value);
          }}
          className="w-full softer-text"
        />
      </ConfigProvider>
      {activeTab === 0 ? (
        <>
          <Table
            className="no-top"
            sticky={true}
            tableLayout="auto"
            loading={!feedbackTexts}
            dataSource={feedbackTexts.map((text, index) => ({
              text,
              key: index,
            }))}
            columns={[
              {
                dataIndex: "text",
                key: "text",
                title: "Feedback",
                render: (feedback) => (
                  <div className="p-1 leading-5 bg-black/10 dark:bg-dark-black/10 border-solid border-px border-black/25 dark:border-dark-black/25 rounded-lg">
                    <span className="text-shade-medium font-bold mr-px">“</span>
                    {feedback}
                    <span className="text-shade-medium font-bold ml-px">”</span>
                  </div>
                ),
              },
            ]}
            pagination={false}
          />
        </>
      ) : activeTab === 1 ? (
        <Table
          className="no-top"
          ref={handleRect}
          tableLayout="auto"
          dataSource={topCompanies
            .map((item) => ({ ...item, key: item.company_name }))
            .sort((item1, item2) => item2.count - item1.count)}
          columns={[
            {
              dataIndex: "company_name",
              key: "company_name",
              title: "Customer",
              render: (company_name, { count }) => (
                <div className="flex flex-row items-center gap-0 w-full h-10 -my-4">
                  <div className="w-0 z-10 flex flex-row justify-start">
                    <div className="shrink-0 m-2.5 px-1 bg-white/50 dark:bg-dark-white/50 leading-5 rounded">
                      {company_name}
                    </div>
                  </div>
                  <div
                    className="absolute left-0 top-0 bottom-0 py-2 px-4 box-border"
                    style={{ width: tableWidth || 0 }}
                  >
                    <div
                      className="bg-primary-light dark:bg-primary-medium h-full rounded-lg"
                      style={{ width: (count / highestTotal) * 100 + "%" }}
                    />
                  </div>
                </div>
              ),
            },
            {
              dataIndex: "count",
              key: "count",
              title: "Feedback Count",
              width: 150,
              sorter: (item1, item2) => item1.count - item2.count,
            },
          ]}
          pagination={false}
        />
      ) : null}
    </>
  );
}
