module.exports = {
  colors: {
    transparent: "rgba(0, 0, 0, 0)",
    white: "#FFFFFF",
    black: "#000000",
    primary: {
      light: "#8E92FF",
      "light-blue": "#456AFF",
      medium: "#2E3192",
      dark: "#080A4A",
      "dark-blue": "#0C163F",
      black: "#040A21",
      base: "#001d3d",
    },
    secondary: "#F5BA16",
    "secondary-light": "#ffd60a",
    "secondary-dark": "#a17b00",
    shade: {
      "medium-light": "#DFDFE4",
      medium: "#919199",
      "medium-dark": "#535466",
      dark: "#303030",
    },
    "landing-text": "#dcddde",
    gold: "#E5B63B",
    "card-blue": "#4C5E7D",
    gray: {
      faint: "#F9FAFB",
      light: "#F0F3F9",
      "medium-light": "#E9EFF6",
      medium: "#8897AE",
      dark: "#5E718D",
      base: "#000814",
    },
    green: {
      light: "#E8F6ED",
      medium: "#0df34e",
      dark: "#11A75C",
    },
    red: {
      light: "#FFF5F4",
      dark: "#FF3838",
    },
    blue: {
      light: "#F2F5FF",
      "medium-light": "#dee5fa",
      dark: "#4A72FF",
      baby: "#56EBFF",
    },
    dark: {
      white: "#161616",
      black: "#d9d9d9",
      primary: "#313182",
      "primary-light": "#07043b",
      "primary-dark": "#6f6af7",
      gray: {
        faint: "#1a1a1a",
        light: "#0C0C0D",
        "medium-light": "#222222",
        medium: "#787878",
        dark: "#a3a3a3",
        base: "#BfBfBf",
      },
      green: {
        light: "#004B2C",
        dark: "#B0D8BD",
      },
      red: {
        light: "#772020",
        dark: "#ddbdbd",
      },
      blue: {
        light: "#1b212e",
        "medium-light": "##cdd7fa",
        dark: "#0D0E11",
        mild: "#121721",
        strong: "#101d38",
      },
    },
  },
  fonts: {
    landing: ["Lato"],
    "landing-header": ["Raleway"],
    sans: ["Sofia Sans"],
    "sans-header": ["Sofia Sans"],
    mono: ["Courier New"],
  },
};
